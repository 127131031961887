<!-- NAVBAR -->
<div class="main-navigation" id="mainmenu-area">
  <div class="container">
    <nav
      class="
        navbar navbar-expand-lg navbar-dark
        bg-primary
        main-nav
        navbar-togglable
        rounded-radius
        mt-4
      "
    >
      <a class="navbar-brand d-lg-none d-block" routerLink="/home">
        <h4>Movements Assessment</h4>
      </a>
      <!-- Toggler -->
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="fa fa-bars"></span>
      </button>

      <!-- Collapse -->
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <!-- Links -->
        <ul class="navbar-nav">
          <li class="nav-item">
            <a routerLink="/project" class="nav-link js-scroll-trigger"> Home </a>
          </li>
          <!-- <li class="nav-item">
            <a routerLink="/project" class="nav-link js-scroll-trigger">
              Project
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/about" class="nav-link js-scroll-trigger">
              About
            </a>
          </li> -->
        </ul>
        <ul class="ml-lg-auto list-unstyled m-0">
          <li>
            <a routerLink="/project" class="btn btn-white btn-circled"
              >Get Started</a
            >
          </li>
        </ul>
      </div>
      <!-- / .navbar-collapse -->
    </nav>
  </div>
  <!-- / .container -->
</div>
