<div class="bg-dark">
  <!--Content -->
  <div class="container">
    <div class="row text-right">
      <div class="col-lg-12 mt-3 mb-2">
        <!-- Copyright -->
        <p class="footer-copy">
          &copy; Copyright <span class="current-year">Ayozat Advertising Platform</span> All
          rights reserved.
        </p>
      </div>
    </div>
    <!-- / .row -->
  </div>
  <!-- / .container -->
</div>
