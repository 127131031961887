<header>
  <app-top-bar></app-top-bar>
  <app-nav></app-nav>
</header>

<router-outlet> </router-outlet>

<footer>
  <app-footer></app-footer>
</footer>
