<section class="section" id="process">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 text-center">
        <div class="section-heading">
          <!-- Heading -->
          <h2 class="section-title">How do I use it?</h2>

          <!-- Subheading -->
          <p>
            Ayozat Advertising Platform is a tool that allows you to generate HLS Video Content with VAST Tag
          </p>
        </div>
      </div>
    </div>
    <!-- / .row -->

    <div class="row justify-content-center">
      <div class="col-lg-4 col-sm-6 col-md-6">
        <div class="process-block">
          <img
            src="../../../assets/images/process/process-1.jpg"
            alt=""
            class="img-fluid"
          />

          <h3>Data Acquisition</h3>
          <p>
            Input Video content and VAST Tag to generate HLS Video Content
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 col-md-6">
        <div class="process-block">
          <img
            src="../../../assets/images/process/process-2.jpg"
            alt=""
            class="img-fluid"
          />

          <h3>Data Processing</h3>
          <p>
            Generate HLS Video Content with VAST Tag
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 col-md-6">
        <div class="process-block">
          <img
            src="../../../assets/images/process/process-3.jpg"
            alt=""
            class="img-fluid"
          />

          <h3>Output Link</h3>
          <p>
            Copy the generated HLS Video Content Link and use it in your player
          </p>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-3">
      <button
        routerLink="/new-project"
        type="button"
        class="btn btn-danger p-4"
      >
        Create New Project
      </button>
    </div>
  </div>
</section>
