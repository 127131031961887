<app-banner></app-banner>

<!-- Project Steps Start -->
<section class="bg-grey" id="service">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 p-3">
        <div class="service-img">
          <img
            src="../../../assets/images/blog-lg.jpg"
            alt=""
            class="img-fluid"
          />
        </div>
      </div>

      <div class="col-lg-6 pl-4">
        <div class="service-content">
          <h1>Our Research Project Steps</h1>
          <p style="text-align: justify; padding-right: 10px">
            In order to detect fidgety movements, identification of circular
            movements is important. This is the first recurrent neural network
            based machine learning model to identify fidgety movements using
            circular movements while capturing movement data using the Kinect
            Xbox 360 sensor. The classification is carried out using features
            that are based on the rotations of joints. As the performance
            measurements of the model using adults is quite high, this model is
            expected to perform in the same manner with actual infant data.This
            model provides promising results over 90% sensitivity with more than
            85% specificity and precision when considering either rotations or
            coordinates. It proves that our model can be used to identify
            Cerebral Palsy for further research.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="pt-5 service-wrap">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 offset-lg-4">
        <div class="carousel slide" id="service-carousel" data-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="service-block media">
                      <div class="service-icon">
                        <i class="ti-arrow-right"></i>
                      </div>
                      <div class="service-inner-content media-body">
                        <h4>1. Video Recording</h4>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="service-block media">
                      <div class="service-icon">
                        <i class="ti-arrow-right"></i>
                      </div>
                      <div class="service-inner-content media-body">
                        <h4>
                          2. Data Acquisition (Skeleton Joint Information)
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="carousel-item">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="service-block media">
                      <div class="service-icon">
                        <i class="ti-arrow-right"></i>
                      </div>
                      <div class="service-inner-content media-body">
                        <h4>3. Data Preprocessing</h4>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="service-block media">
                      <div class="service-icon">
                        <i class="ti-arrow-right"></i>
                      </div>
                      <div class="service-inner-content media-body">
                        <h4>
                          4. Machine Learning Model Development (LSTM RNN)
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="carousel-item">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="service-block media">
                      <div class="service-icon">
                        <i class="ti-arrow-right"></i>
                      </div>
                      <div class="service-inner-content media-body">
                        <h4>5. Model Training</h4>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="service-block media">
                      <div class="service-icon">
                        <i class="ti-arrow-right"></i>
                      </div>
                      <div class="service-inner-content media-body">
                        <h4>6. Model Testing (Movement Classification)</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Project Steps End -->
<hr />
<app-project></app-project>
