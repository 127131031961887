<div class="top-bar bg-dark" id="top-bar">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6">
        <div class="top-bar-left text-white p-4">
          <h2 class="text-white">Ayozat Advertising Platform</h2>
        </div>
      </div>
    </div>
  </div>
</div>
