import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProjectDataService } from 'src/app/core/service/project-data.service';

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.scss']
})
export class CreateProjectComponent implements OnInit {
  @ViewChild('textAreaCopy') textAreaCopy: ElementRef;
  active = 1;
  Output: number;

  formGroup: FormGroup = new FormGroup({
    HLS_Video_Content_Link: new FormControl('', [Validators.required]),
    VAST_Tag_Link: new FormControl('', [Validators.required])
  });

  constructor(private projectDataService: ProjectDataService) { }

  ngOnInit(): void {
  }

  onSubmit() {
    const data =
    {
      hls: this.formGroup.controls.HLS_Video_Content_Link.value,
      vast: this.formGroup.controls.VAST_Tag_Link.value
    }


    console.log(data);
    if (this.formGroup.valid == true) {
      this.projectDataService.getOutputLink(data).subscribe(res => {
        console.log(res);
        this.Output = res.generatedUrl;
        this.active = 2;
        console.log('Succefully Added');
        this.formGroup.reset();
      });
    }
    else {
      console.log('Something wrong');
    }

  }


  copyTextArea() {
    this.textAreaCopy.nativeElement.focus();
    this.textAreaCopy.nativeElement.select();

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Copying text was ' + msg);
    } catch (err) {
      console.error('Oops, unable to copy', err);
    }
  }

}
