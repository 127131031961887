<section class="section" id="process">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 text-center">
        <div class="section-heading">
          <!-- Heading -->
          <h2 class="section-title">About Our Project</h2>

          <!-- Subheading -->
          <p class="text-justify">
            Cerebral Palsy (CP) is a neurological condition that is caused by
            brain damage. Early diagnosis of CP is extremely difficult. However,
            it can improve the possibility of a patient receiving specific early
            intervention to optimize neuroplasticity and prevent complications.
            At the age of six to nine weeks, characteristics of general
            movements of a normal infant vary from Writhing Movement (WM) type
            to Fidgety Movement (FM) type. One of the available methods of
            diagnosing CP is by analyzing fidgety circular movement patterns.
            General Movements Assessment (GMA) is a non-invasive and
            cost-effective way of identifying CP. It is being used to diagnose
            CP, by identifying nerve issues that lead to CP. It is currently
            being carried out manually by a well-trained physician. The motive
            of this project is to introduce a novel machine learning model to
            distinguish circular movements out of the rest of the movements
            using a Kinect sensor while automating the GMA. A movement dataset
            was generated by using adults and a recurrent neural network was
            used for the classification. In accordance with the circular
            movements identification, this model had a sensitivity (91.3%),
            specificity (85.7%) and precision (88.45%). Further, a comparison
            between the accuracy and precision levels, and processing times with
            respect to different frame rates in identifying circular movements
            was carried out. This study shows that the frame rate of a video is
            correlated with the precision of the model. The influence that the
            processing time has on the accuracy of identification is also
            studied. The highest level of accuracy (94.4%) and precision (95.0%)
            was displayed with the increment of frame rate. This model can be
            used with infant data to distinguish circular fidgety movements from
            rest of the movements after appropriate training.
          </p>
        </div>
      </div>
      <div class="col-md-4 col-lg-6">
        <img
          src="../../../assets/images/blog-lg.jpg"
          alt=""
          style="height: 45rem; border: 3px solid #d2c2c2"
        />
      </div>
    </div>
  </div>
</section>
