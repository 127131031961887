<section class="section" id="process">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 text-center">
        <div class="section-heading">
          <!-- Heading -->
          <h2 class="section-title">HLS Videos VAST Ads Generator</h2>
        </div>
      </div>
    </div>
    <div class="justify-content-center">
      <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
        <li [ngbNavItem]="1">
          <a ngbNavLink>Step #01</a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="col-4">
                <img
                  src="../../../assets/images/SCTE-35-program-diagram.webp"
                  alt=""
                  class="img-fluid"
                />
              </div>
              <div class="col-8">
                <form [formGroup]="formGroup" (validSubmit)="onSubmit()">
                  <div class="form-group">
                    <label>HLS Video Content Link</label>
                    <textarea
                      class="form-control"
                      rows="3"
                      placeholder="HLS Video Content Link"
                      formControlName="HLS_Video_Content_Link"
                    ></textarea>
                  </div>
                  <div class="form-group">
                    <label>VAST Tag Link</label>
                    <textarea
                      class="form-control"
                      rows="3"
                      placeholder="VAST Tag Link"
                      formControlName="VAST_Tag_Link"
                    ></textarea>
                  </div>
                  <button type="submit" 
                  class="btn btn-primary">
                    Generate
                  </button>
                </form>
              </div>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink>Final Result</a>
          <ng-template ngbNavContent>
            <div class="row justify-content-md-center">
              <h4>Generated Link</h4>
            </div>
            <div class="row justify-content-md-center">
              <div *ngIf="Output">
                <textarea class="form-control" #textAreaCopy cols="35" rows="4">{{Output}}</textarea>
                <br>
                <button class="btn btn-primary" (click)="copyTextArea()">Copy to clipboard</button>
              </div>
            </div>
          </ng-template>
        </li>
      </ul>

      <div [ngbNavOutlet]="nav" class="mt-5"></div>
    </div>
  </div>
</section>
